import React, { useState, useEffect } from 'react';
import logosmall from '../images/logosmall.gif'

const Footer = () => {
    return (
        <div>
            <div class="footer1">
                <ul class='navList-footer'>
                </ul>
                <span class='footerText'>This media is under exclusive right to its creators.</span>
                <span class='footerText-mobile'>COLLABORATIVE PROJECTS, all media is under exclusive right to its creators.</span>
                <a className='linkblue' href='mailto:wianrossouw@protonmail.com'>wianrossouw<span class="page-titleactive">@</span>protonmail.com</a>
                <img class="logosmall" src={logosmall} alt="logo"></img>
            </div>
        </div>
    )
};

export default Footer

