import logo from './logo.svg';
import './App.css';
import Main from './components/main.js'
import Hero from './components/hero.js'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './components/footer.js'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='#' element={<Footer />} />
        </Routes>
      </BrowserRouter>
    </div >
  );
}

export default App;
