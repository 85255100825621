import React, { useState, useEffect } from 'react';
import wianrossouw from '../images/wianrossouw.png'
import copyspotgrey from '../images/copyspot.jpg'
import kolekat from '../images/kolekat.jpg'
import trudiamonds from '../images/trudiamonds4.jpg'
import maverix from '../images/maverix.jpg'
import logo from "../images/LOGOSVG.svg"
import sidebag from "../images/sidebag.jpg"
import logosmall from "../images/logosmall.gif"
import bertuzzi from "../images/bertuzzi.jpg"
import vsalogo from "../images/vsalogo.jpg"
import betafence from "../images/betafence.jpg"
import crocs from "../images/crocs.webp"
import birkenstock from "../images/birkenstock.webp"
import vans from "../images/vans.jpg"
import underarmomr from "../images/underarmo.jpg"
import derm from "../images/derm.webp"
import frut from "../images/frut.webp"
import bb from "../images/bigblue.webp"
import heydude from "../images/hey-dude.png"


import { Link } from 'react-router-dom';
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";

function Main() {

    const [isHovered, setHoveredId] = useState(false);

    const handleHover = (id) => {
        setHoveredId(id);
    };

    const handleLeave = () => {
        setHoveredId(null);
    };

    const [loaded, setLoaded] = useState(false);

    useEffect = () => {
        const img1 = new Image();
        const img2 = new Image();
        const img3 = new Image();
        const img4 = new Image();
        const img5 = new Image();
        const img6 = new Image();
        const img7 = new Image();
        const img8 = new Image();


        img1.onload = img2.onload = () => setLoaded(true);
        img1.src = { betafence };
        img2.src = { trudiamonds };
        img3.src = { vsalogo };
        img4.src = { kolekat };
        img5.src = { bertuzzi };
        img6.src = { sidebag };
        img7.src = { copyspotgrey };
        img8.src = { betafence };



    };

    return (

        <html lang="en-US">
            <head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@AdobePortfolio" />
                <meta property="og:title" content="TSAI MING HSUN DESIGN WORKS - CLIENT WORKS" />
                <meta property="og:image" content="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/89b2de52-c057-484e-9965-11487808066b_rwc_0x0x2951x2209x2951.png?h=c92d4f3aeab9f5a9f10b5b353513a997" />
                <link rel="icon" href="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/6cf8a740-7db8-456e-a488-4b96987af856_carw_1x1x32.png?h=1fee9ef191438ac3f46b433279e411db" />
                <link rel="apple-touch-icon" href="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/ddd99e07-a066-471b-937b-824523fbd788_carw_1x1x180.jpg?h=71ee55ccecab5c8d6b7cd2e4ff84370c" />
                <link rel="stylesheet" href="/dist/css/main.css" type="text/css" />
                <link rel="stylesheet" href="app.css" type="text/css" />
                <link rel="canonical" href="https://blindvicegraphics.myportfolio.com/work" />
                <title>TSAI MING HSUN DESIGN WORKS - CLIENT WORKS</title>
                <script type="text/javascript" src="//use.typekit.net/ik/AY3lcHwcUERfxr7DTevpHJKSCjlLGDW7O-jO_K2FINCfe7jgfHYEBsJzwD9oFDIDWDMaFQSaZQIhFcbujcmKZ2qawhbUF2Z8wcFKwQFqwQIoFRwUFeIk5Aj-mkG0dW83da4XZcNC-Av0jhNlOfG0SY4zwKuh-AmaOcuoSeNkieZzde8zOcFzdPUlpWgzS1scdhUTdkoRdhXCSY4zwKuh-AmaOcuoSeNkieZzde8zOcFzdPJIicFDdPG4fJCgIMMjgkMgqMeXDlDbgb.js?cb=d6bf889fa22087bba74177ce4553667c1cd167f9" async onload="
    try {
      window.Typekit.load();
    } catch (e) {
      console.warn('Typekit not loaded.');
    }
    "></script>
            </head>
            <body class="transition-enabled">  <div class='page-background-video page-background-video-with-panel'>
            </div>
                <div class="js-responsive-nav">
                    <div class="responsive-nav has-social">
                        <div class="close-responsive-click-area js-close-responsive-nav">
                            <div class="close-responsive-button"></div>
                        </div>
                        <nav data-hover-hint="nav">
                            <div class="page-title">
                                <a href="href=mailto:wianrossouw@protonmail.com" >CONTACT</a>
                            </div>
                        </nav>
                        <Link to="/"> <a className='activelink'>LATEST WEBSITES</a></Link>
                    </div>
                </div>
                <div class="site-wrap cfix js-site-wrap">
                    <div class="site-container">
                        <div class="site-content e2e-site-content">
                            <div class="sidebar-content">
                                <header class="site-header">
                                    <div class="logo-wrap" data-hover-hint="logo">
                                        <div class="logo e2e-site-logo-text logo-image has-rollover ">
                                            <a href="#" class="image-normal image-link">
                                                <img class="logodesktop" src={logo} alt="WIAN ROSSOUW DIGITAL DEVELOPMENT"></img>
                                                <img class="logomobile2" src={logo} alt="WIAN ROSSOUW DIGITAL DEVELOPMENT"></img>
                                            </a>
                                            <a href="#" class="image-rollover image-link">
                                                <img src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/2702d0b8-d5d1-46e4-8b19-a01b7e7e6a80_rwc_0x9x1791x1791x4096.png?h=d640b0aad22a458342311ce01c6b1438" alt="TSAI MING HSUN DESIGN WORKS"></img>
                                            </a>
                                        </div>
                                    </div>
                                    <span class="hamburger-click-area js-hamburger"><a class="linkblue2" href='mailto:wianrossouw@protonmail.com'>CONTACT</a></span>
                                </header>
                                <nav data-hover-hint="nav">
                                    <div class="page-title">
                                        <a href="mailto:wianrossouw@protonmail.com" >CONTACT</a>
                                    </div>
                                    <div class="page-titleactive">
                                        <Link to="/"> <a className='page-titleactive'>LATEST WEBSITES</a></Link>
                                    </div>
                                    <div class="footer1">
                                        <ul class='navList-footer'>
                                        </ul>
                                        <span class='footerText'>COLLABORATIVE PROJECTS, all media is under exclusive right to its creators.</span>
                                        <a className='linkblue' href='mailto:wianrossouw@protonmail.com'>wianrossouw<span class="page-titleactive">@</span>protonmail.com</a>
                                        <img class="logosmall" src={logosmall} alt="logo"></img>
                                    </div>
                                </nav>
                            </div>
                            <main>
                                <section class="project-covers" data-context="page.gallery.covers">
                                    <a class="project-cover js-project-cover-touch hold-space" target="_blank" href="https://www.crocssa.co.za" data-context="pages" data-identity="id:p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint-id="p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("maverix")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap ${loaded ? 'loaded' : ''}`">
                                            <div class="cover-image">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={crocs}
                                                        data-src="https://cdn.shopify.com/s/files/1/0587/1983/0105/files/Crocs.webp?v=1705943418"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x640.jpg?h=7edcefca7e6bdaa4e0c8689eb2db9cbb 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1280.jpg?h=af6dac879dc6a507a731b692d792edaf 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1366.jpg?h=71c3b585df6beca54db39b06778354a9 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1920.jpg?h=eb9819172ba94c2cbb424be834da2a3f 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2560.jpg?h=b50693a488a07891d450cf44abe622b2 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">CROCS ZA</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" target="_blank" href="https://bashafrica.com/" data-context="pages" data-identity="id:p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint-id="p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("maverix")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap ${loaded ? 'loaded' : ''}`">
                                            <div class="cover-image">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={birkenstock}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x640.jpg?h=7edcefca7e6bdaa4e0c8689eb2db9cbb 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1280.jpg?h=af6dac879dc6a507a731b692d792edaf 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1366.jpg?h=71c3b585df6beca54db39b06778354a9 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1920.jpg?h=eb9819172ba94c2cbb424be834da2a3f 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2560.jpg?h=b50693a488a07891d450cf44abe622b2 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">BIRKENSTOCK ZA</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" target="_blank" href="https://vans.co.za/" data-context="pages" data-identity="id:p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint-id="p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("maverix")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap ${loaded ? 'loaded' : ''}`">
                                            <div class="cover-image">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={vans}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x640.jpg?h=7edcefca7e6bdaa4e0c8689eb2db9cbb 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1280.jpg?h=af6dac879dc6a507a731b692d792edaf 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1366.jpg?h=71c3b585df6beca54db39b06778354a9 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1920.jpg?h=eb9819172ba94c2cbb424be834da2a3f 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2560.jpg?h=b50693a488a07891d450cf44abe622b2 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">VANS ZA</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" target="_blank" href="https://underarmour.co.za" data-context="pages" data-identity="id:p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint-id="p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("maverix")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap ${loaded ? 'loaded' : ''}`">
                                            <div class="cover-image">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={underarmomr}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x640.jpg?h=7edcefca7e6bdaa4e0c8689eb2db9cbb 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1280.jpg?h=af6dac879dc6a507a731b692d792edaf 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1366.jpg?h=71c3b585df6beca54db39b06778354a9 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1920.jpg?h=eb9819172ba94c2cbb424be834da2a3f 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2560.jpg?h=b50693a488a07891d450cf44abe622b2 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">UNDER ARMOUR ZA</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" target="_blank" href="https://dermalogica.co.za/" data-context="pages" data-identity="id:p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint-id="p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("maverix")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap ${loaded ? 'loaded' : ''}`">
                                            <div class="cover-image">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={derm}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x640.jpg?h=7edcefca7e6bdaa4e0c8689eb2db9cbb 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1280.jpg?h=af6dac879dc6a507a731b692d792edaf 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1366.jpg?h=71c3b585df6beca54db39b06778354a9 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1920.jpg?h=eb9819172ba94c2cbb424be834da2a3f 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2560.jpg?h=b50693a488a07891d450cf44abe622b2 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">DERMALOGICA</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" target="_blank" href="https://frut.co.za/" data-context="pages" data-identity="id:p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint-id="p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("maverix")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap ${loaded ? 'loaded' : ''}`">
                                            <div class="cover-image">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={frut}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x640.jpg?h=7edcefca7e6bdaa4e0c8689eb2db9cbb 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1280.jpg?h=af6dac879dc6a507a731b692d792edaf 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1366.jpg?h=71c3b585df6beca54db39b06778354a9 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1920.jpg?h=eb9819172ba94c2cbb424be834da2a3f 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2560.jpg?h=b50693a488a07891d450cf44abe622b2 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">FRÜT</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" target="_blank" href="https://www.bigblue.co.za/" data-context="pages" data-identity="id:p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint-id="p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("maverix")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap ${loaded ? 'loaded' : ''}`">
                                            <div class="cover-image">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={bb}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x640.jpg?h=7edcefca7e6bdaa4e0c8689eb2db9cbb 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1280.jpg?h=af6dac879dc6a507a731b692d792edaf 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1366.jpg?h=71c3b585df6beca54db39b06778354a9 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1920.jpg?h=eb9819172ba94c2cbb424be834da2a3f 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2560.jpg?h=b50693a488a07891d450cf44abe622b2 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">BIG BLUE</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" target="_blank" href="https://heydudeshoes.co.za/" data-context="pages" data-identity="id:p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint-id="p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("maverix")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap ${loaded ? 'loaded' : ''}`">
                                            <div class="cover-image">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={heydude}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x640.jpg?h=7edcefca7e6bdaa4e0c8689eb2db9cbb 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1280.jpg?h=af6dac879dc6a507a731b692d792edaf 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1366.jpg?h=71c3b585df6beca54db39b06778354a9 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1920.jpg?h=eb9819172ba94c2cbb424be834da2a3f 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2560.jpg?h=b50693a488a07891d450cf44abe622b2 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">HEYDUDE</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>                      
                                    <a class="project-cover js-project-cover-touch hold-space" href="https://betafence-hekwerken.nl/" data-context="pages" data-identity="id:p630c8dcd9f9c6991ddc9b20842ad9cce065d0729fb0097cb79414" data-hover-hint-id="p630c8dcd9f9c6991ddc9b20842ad9cce065d0729fb0097cb79414" data-hover-hint="galleryPageCover">
                                        <div class="cover-image-wrap">
                                            <div class="cover-image">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={betafence}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x642.jpg?h=efd03368b1abcd3a2f452a3253f3e380"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x640.jpg?h=f730c0cbbc62b7b874f55d07ef1c8b7f 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x1280.jpg?h=7a948731b2c265a1f2b01d3f13da2ccd 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x1366.jpg?h=22de47e3e9687f222b582f96603818c2 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x642.jpg?h=efd03368b1abcd3a2f452a3253f3e380 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x642.jpg?h=efd03368b1abcd3a2f452a3253f3e380 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x642.jpg?h=efd03368b1abcd3a2f452a3253f3e380 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">BETAFENCE NETHERLANDS</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" target="_blank" href="https://www.trudiamonds.co.uk" data-context="pages" data-identity="id:p630c8dcd9f9c6991ddc9b20842ad9cce065d0729fb0097cb79414" data-hover-hint-id="p630c8dcd9f9c6991ddc9b20842ad9cce065d0729fb0097cb79414" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("trudiamonds")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap ${loaded ? 'loaded' : ''}`">
                                            <div class="cover-image">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={trudiamonds}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x642.jpg?h=efd03368b1abcd3a2f452a3253f3e380"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x640.jpg?h=f730c0cbbc62b7b874f55d07ef1c8b7f 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x1280.jpg?h=7a948731b2c265a1f2b01d3f13da2ccd 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x1366.jpg?h=22de47e3e9687f222b582f96603818c2 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x642.jpg?h=efd03368b1abcd3a2f452a3253f3e380 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x642.jpg?h=efd03368b1abcd3a2f452a3253f3e380 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/dffbc41d-fdb8-4a71-afa3-f22447eb5109_rwc_382x329x642x642x642.jpg?h=efd03368b1abcd3a2f452a3253f3e380 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">TRUDIAMONDS UK // TRUDIAMONDS USA</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>

                                    <a class="project-cover js-project-cover-touch hold-space" target="blank" href="https://vsadesigns.com/" data-context="pages" data-identity="id:p630c8b356fb756050268c0447dafe129e74a6f898b604fd6c0c06" data-hover-hint-id="p630c8b356fb756050268c0447dafe129e74a6f898b604fd6c0c06" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("kolekat")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap">
                                            <div class="cover-image ${loaded ? 'loaded' : ''}`">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={vsalogo}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x2007.jpg?h=9aba9a03e9504269d00c9419109cb2f4"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x640.jpg?h=0498d937a14ae668fba5ff152715ed26 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x1280.jpg?h=1dba2b5893e07fc7fb8ca1ed383cf5b2 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x1366.jpg?h=16815c3c3f2fc4f92d9f62a8e6aaf0f3 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x1920.jpg?h=5807f23f8d4d7b59010c74df30cae96e 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x2560.jpg?h=df586eeecad39167d8088357dab16217 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x2007.jpg?h=9aba9a03e9504269d00c9419109cb2f4 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">VIRGINS SAINTS & ANGELS</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" target="_blank" href="" data-context="pages" data-identity="id:p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint-id="p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("maverix")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap ${loaded ? 'loaded' : ''}`">
                                            <div class="cover-image">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={maverix}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x640.jpg?h=7edcefca7e6bdaa4e0c8689eb2db9cbb 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1280.jpg?h=af6dac879dc6a507a731b692d792edaf 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1366.jpg?h=71c3b585df6beca54db39b06778354a9 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1920.jpg?h=eb9819172ba94c2cbb424be834da2a3f 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2560.jpg?h=b50693a488a07891d450cf44abe622b2 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">BYMAVERIX [deprecated]</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" href="#" data-context="pages" data-identity="id:p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint-id="p630c8b35e7f5b54a2da14e75829addf22c9fa83cf93fd428487cf" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("maverix")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap">
                                            <div class="cover-image ${loaded ? 'loaded' : ''}`">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={bertuzzi}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x640.jpg?h=7edcefca7e6bdaa4e0c8689eb2db9cbb 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1280.jpg?h=af6dac879dc6a507a731b692d792edaf 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1366.jpg?h=71c3b585df6beca54db39b06778354a9 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x1920.jpg?h=eb9819172ba94c2cbb424be834da2a3f 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2560.jpg?h=b50693a488a07891d450cf44abe622b2 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/1bde9585-d1a0-4485-b4cb-1052bcdf811a_rwc_1191x1253x2702x2702x2702.jpg?h=6f0dff013cbeb5226d9c892e8e0c9bf0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">BERTUZZI [deprecated]</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" href="#" data-context="pages" data-identity="id:p630c8b35273ab9482e279c76dfdd824dab950c18bb6d125e780e7" data-hover-hint-id="p630c8b35273ab9482e279c76dfdd824dab950c18bb6d125e780e7" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("copyspot")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap">
                                            <div class="cover-image ${loaded ? 'loaded' : ''}`">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={sidebag}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x1703.jpg?h=407cf07ddb2b5aa7015621b15e5966f0"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x640.jpg?h=19706243ed519283d8be7e6455030b0f 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x1280.jpg?h=4620420ccaf97e608e60c178a0c71031 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x1366.jpg?h=f9650764ee331c3883763d7bb0983d34 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x1920.jpg?h=9ae065e858eee0c773a22d7354f26115 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x2560.jpg?h=10adf403814d463e8d1f7271418b5ddc 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x1703.jpg?h=407cf07ddb2b5aa7015621b15e5966f0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">SKHWAMA [deprecated]</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" href="#" data-context="pages" data-identity="id:p630c8b35273ab9482e279c76dfdd824dab950c18bb6d125e780e7" data-hover-hint-id="p630c8b35273ab9482e279c76dfdd824dab950c18bb6d125e780e7" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("copyspot")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap">
                                            <div class="cover-image ${loaded ? 'loaded' : ''}`">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={copyspotgrey}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x1703.jpg?h=407cf07ddb2b5aa7015621b15e5966f0"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x640.jpg?h=19706243ed519283d8be7e6455030b0f 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x1280.jpg?h=4620420ccaf97e608e60c178a0c71031 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x1366.jpg?h=f9650764ee331c3883763d7bb0983d34 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x1920.jpg?h=9ae065e858eee0c773a22d7354f26115 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x2560.jpg?h=10adf403814d463e8d1f7271418b5ddc 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/fd5ad23d-027e-4caf-9e67-8320813c9d0b_rwc_820x1889x1703x1703x1703.jpg?h=407cf07ddb2b5aa7015621b15e5966f0 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">COPYSPOT.LTD [deprecated]</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                    <a class="project-cover js-project-cover-touch hold-space" href="#" data-context="pages" data-identity="id:p630c8b356fb756050268c0447dafe129e74a6f898b604fd6c0c06" data-hover-hint-id="p630c8b356fb756050268c0447dafe129e74a6f898b604fd6c0c06" data-hover-hint="galleryPageCover" onMouseEnter={() => handleHover("kolekat")} onMouseLeave={handleLeave}>
                                        <div class="cover-image-wrap">
                                            <div class="cover-image ${loaded ? 'loaded' : ''}`">
                                                <div class="cover cover-normal">

                                                    <img
                                                        class="cover__img js-lazy"
                                                        src={kolekat}
                                                        data-src="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x2007.jpg?h=9aba9a03e9504269d00c9419109cb2f4"
                                                        data-srcset="https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x640.jpg?h=0498d937a14ae668fba5ff152715ed26 640w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x1280.jpg?h=1dba2b5893e07fc7fb8ca1ed383cf5b2 1280w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x1366.jpg?h=16815c3c3f2fc4f92d9f62a8e6aaf0f3 1366w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x1920.jpg?h=5807f23f8d4d7b59010c74df30cae96e 1920w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x2560.jpg?h=df586eeecad39167d8088357dab16217 2560w, https://cdn.myportfolio.com/931a4ab3-ea03-4b1e-89d0-972435934e13/be0c62a2-eb37-4766-b1e1-c24fa6ab7860_rwc_775x1670x2007x2007x2007.jpg?h=9aba9a03e9504269d00c9419109cb2f4 5120w"
                                                        data-sizes="(max-width: 540px) 100vw, (max-width: 768px) 50vw, calc(1400px / 4)"
                                                    ></img>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="details-wrap">
                                            <div class="details">
                                                <div class="details-inner">
                                                    <div class="title preserve-whitespace">KOLEKAT [deprecated]</div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </section>
                                <div>
                                    <div class="footer2">
                                        <ul class='navList-footer'>
                                        </ul>
                                        <img class="logosmall2" src={logosmall} alt="logo"></img>

                                        <span class='footerText-mobile'>COLLABORATIVE PROJECTS, all media is under exclusive right to its creators.<br></br><a className='linkblue' href='mailto:wianrossouw@protonmail.com'>wianrossouw<span class="page-titleactive">@</span>protonmail.com</a></span>
                                    
                                    </div>
                                </div>
                                <section class="back-to-top" data-hover-hint="backToTop">
                                    <a href="#"><span class="arrow">&uarr;</span><span class="preserve-whitespace">Back to Top</span></a>
                                </section>
                            </main>
                        </div>
                    </div>
                </div>
            </body>
        </html>
    )
};
export default Main;